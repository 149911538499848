import { useEffect, useState } from "react";
import { Alert, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import ProductCategory from "./ProductCategory";
import styles from './style.module.css';
import { updatePropertyInList, updatePropertyInListNoFixed } from "./utils";

export default function TypeProductCategory({ jwt, type, products, fileOptions, onUpdate, categories, onSum, onWeight, discountProducts, additionCoefficient  }) {

  const [currentCategory] = useState(categories.filter(f => f.categoryId == type.id));
  const [amounts, setAmounts] = useState([]);

  const getSumAfterDiscount = (sum, productId) => {

    if(!productId)
      return sum;

    const finds = +(discountProducts.filter(f => f.products.findIndex(findOne => findOne.id == productId) != -1 && f.math == 2)).reduce((a, b) => a + +b.value, 0);

    return sum - sum * (finds / 100);
  }

  useEffect(() => {

    let sum = 0;
    for (let i = 0; i < amounts.length; i++) {
      const element = amounts[i];
      sum += element.discountSum = getSumAfterDiscount(element.sum, element?.productId);
    }

    const categoriesOnDiscounts = amounts.filter(f => discountProducts.findIndex(find => find.products.findIndex(findOne => findOne.id == f.productId) != -1 && find.math == 1) != -1);
    const amountsAllOnDiscounts = +(categoriesOnDiscounts.reduce((a, b) => a + +b.sum, 0));
    const amountsDiscountValue = +(discountProducts.filter(f => f.products.findIndex(findOne => categoriesOnDiscounts.findIndex(find => find.productId == findOne.id) != -1) != -1 && f.math == 1)).reduce((a, b) => a + +b.value, 0);
           
    onSum(type, sum - amountsAllOnDiscounts + (amountsAllOnDiscounts - amountsDiscountValue));

  }, [amounts, discountProducts]);

 

  return <div className={styles.typeProductCategory}>
    <Stack gap={3} direction="horizontal">
      <div className={styles.typeColor} style={{ backgroundColor: type.color }}/>
    <OverlayTrigger overlay={<Tooltip id={`type-tooltip-${type.id}`}>{type.description}</Tooltip>}>
      <h4>{type.name}</h4>
            </OverlayTrigger>
    </Stack>
    <div className={styles.bodyCategories}>
      {
        currentCategory && currentCategory.length > 0 ?
          currentCategory.flatMap(o => o.countView > 1 ? Array.from({ length: o.countView }).fill(o, 0, o.countView) : o )
            .map((o, index) => <ProductCategory
            additionCoefficient={additionCoefficient}
            fileOptions={fileOptions}
            keyUnq={`${o.id}-${index}`}
            onSum={(c, s, p) => setAmounts(updatePropertyInListNoFixed(amounts, 'key', `${o.id}-${index}`, 'sum', s))} 
            onWeight={(c, s) => setAmounts(updatePropertyInList(amounts,'key', `${o.id}-${index}`, 'weight', s, (s) => onWeight(type, s)))} 
            onUpdateProduct={(p) => p ? 
              setAmounts([...amounts.filter(filter => filter.key != `${o.id}-${index}`),
                {
                  key: `${o.id}-${index}`, 
                  sum: 0, 
                  weight: 0,
                  productId: p.id
                }]) 
               : setAmounts([...amounts.filter(filter => filter.key != `${o.id}-${index}`)])}
            key={index}
            onUpdate={(p) => onUpdate({
              ...p,
              key: `${o.id}-${index}`
            })}
            category={o} 
            products={products} 
            jwt={jwt} />) :
          <Alert variant="warning">Пусто</Alert>
      }
    </div>
  </div>
}
