import { useState } from "react";
import { Alert, Badge, Button, Col, Container, FloatingLabel, Form, InputGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { MdCheck, MdClose, MdOutlineTipsAndUpdates } from "react-icons/md";
import ExtraWorkAdd from "./ExtraWorksAdd";
import { deleteExtraWorkPull, updateExtraWorkPull, updateShiftAsync } from "./api";
import ExtraWorkEdit from "./ExtraWorkEdit";

export default function ShiftEdit({ shift, show, onHide, onUpdated, jwt, machinesAll, extraWorksAll, categoriesExtraWorksAll }) {

    const [startDateSimpleZone] = useState(shift.start.includes('.') ? shift.start.split('.')[0] : shift.start.replace('Z', ''));
    const [endDateSimpleZone] = useState(shift.end.includes('.') ? shift.end.split('.')[0] : shift.end.replace('Z', ''));
    const [endMachineDateSimpleZone] = useState(shift.end.includes('.') ? shift.endMachine?.split('.')[0]: shift.endMachine.replace('Z', ''));
    const [zone, setZone] = useState(shift.zone ?? '');
    const [startDate, setStartDate] = useState(new Date(startDateSimpleZone).addHours(zone.split(':')[0]).addHours(new Date().getTimezoneOffset()/-60).toJSON().split('.')[0]);
    const [endDate, setEndDate] = useState(new Date(endDateSimpleZone).addHours(zone.split(':')[0]).addHours(new Date().getTimezoneOffset()/-60).toJSON().split('.')[0]);
    const [endMachineDate, setEndMachineDate] = useState(new Date(endMachineDateSimpleZone).addHours(zone.split(':')[0]).addHours(new Date().getTimezoneOffset()/-60).toJSON().split('.')[0]);

    const [extraWorks, setExtraWorks] = useState(shift.extraWorksPull);
    const [machines, setMachines] = useState(shift.machines);

    const [isExtraWorksAdd, setIsExtraWorksAdd] = useState(false);
    const [isRemathMachines, setIsRemathMachines] = useState(false);
    const [isDayOff, setIsDayOff] = useState(shift.isDayOff);
    const [machinePercent, setMachinePercent] = useState(shift.machinePercent);

    const [extraWorkEdit, setExtraWorkEdit] = useState();

    const onSubmit = async(e) => {
        e.preventDefault();
        
        const response = await updateShiftAsync(shift.id, {
            start: new Date(startDate).addHours(new Date().getTimezoneOffset()/-60).addHours(zone.split(':')[0] * -1).toJSON(),
            end: new Date(endDate).addHours(new Date().getTimezoneOffset()/-60).addHours(zone.split(':')[0] * -1).toJSON(),
            zone: zone,
            isDayOff: isDayOff,
            endMachine: new Date(endMachineDate).addHours(new Date().getTimezoneOffset()/-60).addHours(zone.split(':')[0] * -1).toJSON(),
            machines: machines,
            isRemathMachines: isRemathMachines,
            machinePercent: machinePercent / 100
        }, jwt);


        if(!response)
        {
            alert('Упс... что-то пошло не по плану')
            return;
        }
            
        onUpdated({ ...response, worker: shift.worker });
    }

    const removeExtraWork = async(work) => {

        const response = await deleteExtraWorkPull(jwt, work.id);

        const works = [...extraWorks.filter(f => f.id != work.id)];
        shift.extraWorksPull = works;
        setExtraWorkEdit();
        setExtraWorks(works);
    }

    const updateExtraWork = async(work) => {

        const response = await updateExtraWorkPull(jwt, work.id, work);

        const index = extraWorks.findIndex(f => f.id == work.id);
        extraWorks[index] = work;
        const works = [...extraWorks];
        shift.extraWorksPull = works;
        setExtraWorkEdit();
        setExtraWorks(works);
    }

    const createExtraWork = (work) => {

        const works = [...extraWorks, work];
        shift.extraWorksPull = works;
        setIsExtraWorksAdd(false);
        setExtraWorks(works);
    }

    if(extraWorkEdit)
        return <ExtraWorkEdit extraWork={extraWorkEdit} 
                onDeleted={() => removeExtraWork(extraWorkEdit)}
                onHide={() => setExtraWorkEdit()}
                onUpdated={(up) => updateExtraWork(up)}
                show={extraWorkEdit != null}
                />

    if (isExtraWorksAdd)
        return <ExtraWorkAdd categories={categoriesExtraWorksAll}
            extraWorks={extraWorksAll.filter(f => extraWorks.findIndex(find => find.extraWorkId == f.id) == -1)}
            onHide={() => setIsExtraWorksAdd(false)}
            show={isExtraWorksAdd} 
            jwt={jwt}
            onCreated={createExtraWork}
            shiftId={shift.id}
            />

    return <Modal show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Form onSubmit={onSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span>{shift.worker}</span>
                    <Badge pill bg={shift.isDayOff ? 'warning' : 'success'}>
                        {shift.isDayOff ? 'Выходной' : 'Рабочий'}
                    </Badge>
                    <Badge pill>
                        {shift.zone?.includes('-') ? shift.zone.slice(0, 6) : '+' + shift.zone?.slice(0, 5)}
                    </Badge>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <h5>Время</h5>
                    </Row>
                    <Row>
                        <Col className="mb-3">
                            <FloatingLabel label="Начало" >
                                <Form.Control required type="datetime-local" placeholder="" value={startDate} onChange={e => setStartDate(e.target.value)} />
                            </FloatingLabel>
                            <Form.Text muted>Время по гринвичу, без учета часового пояса смены</Form.Text>
                        </Col>
                        <Col className="mb-3">
                            <FloatingLabel label="Конец">
                                <Form.Control required type="datetime-local" placeholder="" value={endDate} onChange={e => setEndDate(e.target.value)} />
                            </FloatingLabel>
                            <Form.Text muted>Время по гринвичу, без учета часового пояса смены</Form.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mb-3">
                            <FloatingLabel label="Часовой пояс">
                                <Form.Control required type="time" placeholder="" value={zone} onChange={e => setZone(e.target.value)} />
                            </FloatingLabel>
                            <Form.Text muted>Временно, только положительные часы</Form.Text>
                        </Col>
                        <Col className="mb-3">
                            <FloatingLabel label="Окончание станков">
                                <Form.Control required type="datetime-local" disabled={machines.length <= 0} placeholder="" value={endMachineDate} onChange={e => setEndMachineDate(e.target.value)} />
                            </FloatingLabel>
                            <Form.Text muted>Время по гринвичу, без учета часового пояса смены</Form.Text>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Alert variant="info" style={{ display: "flex", alignItems: 'center' }}>
                            <MdOutlineTipsAndUpdates style={{ marginRight: 6 }} />
                            <span>Что значит по <a href="https://ru.wikipedia.org/wiki/%D0%A1%D1%80%D0%B5%D0%B4%D0%BD%D0%B5%D0%B5_%D0%B2%D1%80%D0%B5%D0%BC%D1%8F_%D0%BF%D0%BE_%D0%93%D1%80%D0%B8%D0%BD%D0%B2%D0%B8%D1%87%D1%83">Гринвичу</a>? Что такое <a href="https://ru.wikipedia.org/wiki/UTC%C2%B10:00">UTC</a>?</span>
                        </Alert>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <h5>Станки</h5>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Нагрузка</InputGroup.Text>
                                <Form.Control
                                    disabled={isRemathMachines}
                                    type='number'
                                    value={machinePercent}
                                    onChange={e => setMachinePercent(e.target.value)}
                                    />
                                <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup>
                            {
                                machinesAll.map(a => <Badge key={a.id}
                                    onClick={() => setMachines(machines.findIndex(f => f == a.id) == -1 ? [...machines, a.id] : [...machines.filter(f => f != a.id)])}
                                    bg={machines.findIndex(f => f == a.id) == -1 ? 'secondary' : 'success'}
                                    pill
                                    style={{ marginRight: 4, transition: '0.4s', cursor: 'pointer' }}>
                                    {machines.findIndex(f => f == a.id) == -1 ? <MdClose style={{ width: 18 }} /> : <MdCheck style={{ width: 18 }} />}
                                    <span style={{ userSelect: 'none' }}>{a.name}</span>
                                </Badge>)
                            }
                        </Col>
                    </Row>
                    <Row className="mb-3" style={{ border: '1px dashed red', borderRadius: 4, padding: '4px 0px' }}>
                        <Col>
                            <h5>
                                <span>Работы</span>
                                <Badge bg="danger" pill style={{ marginLeft: 4 }}>Измения вступаю в силу - сразу</Badge>
                            </h5>
                            {
                                extraWorks.map(a => <Badge
                                    key={a.id}
                                    bg={'primary'}
                                    onClick={() => setExtraWorkEdit(a)}
                                    pill
                                    style={{ marginRight: 4, transition: '0.4s', cursor: 'pointer' }}>
                                    <span style={{ userSelect: 'none' }}>{extraWorksAll.find(f => f.id == a.extraWorkId)?.name ?? 'Не найдено'} - {a.value} - {a.points.toFixed(2)}</span>
                                </Badge>)
                            }
                            <Badge bg={'success'}
                                pill
                                onClick={() => setIsExtraWorksAdd(true)}
                                style={{ marginRight: 4, transition: '0.4s', cursor: 'pointer' }}>
                                <span style={{ userSelect: 'none' }}>Добавить</span>
                            </Badge>
                        </Col>
                    </Row>
                    <Row>
                    <Alert variant="info" style={{ display: "flex", alignItems: 'center' }}>
                            <MdOutlineTipsAndUpdates style={{ marginRight: 6 }} />
                            <span>Название - значение работы - баллы</span>
                        </Alert>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-info-machines">Выполненные работы станками будут перерасчитаны и определены в текущую смену. ВАЖНО: Если есть другая смена и идет наложение времени, приоритетом будет последняя сохранившееся. Т.к. две смены не могут претендовать одновременно на одну и ту же работу станков</Tooltip>}
                >
                    <Form.Check
                    type="switch"
                    className="me-auto"
                    checked={isRemathMachines}
                    onChange={e => setIsRemathMachines(e.target.checked)}
                    label={'Перерасчет станков'} />
                </OverlayTrigger>
                <Form.Check
                    type="switch"
                    className="me-auto"
                    checked={isDayOff}
                    onChange={e => setIsDayOff(e.target.checked)}
                    label={'Выходной'} />
                <Button variant="secondary" onClick={onHide}>Закрыть</Button>
                <Button variant="success" type="submit">Сохранить</Button>
            </Modal.Footer>
        </Form>
    </Modal>
}