import React, { useEffect, useState } from "react";
import { lasers } from "../../../../apiMethod/shifts";
import styles from './ShiftEdit.module.css';
import ShiftView from "./ShiftView";
import ShiftEdit from "./ShiftEdit";
import { getExtraWorkCategoriesV1, getExtraWorksV1, getShiftsAllV1, getShiftsMyV1 } from "./api";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

export default function ShiftsHistory({ jwt, isAdmin }) {

    const { currentDateView } = useParams();
    const [currentDate, setCurrentDate] = useState(moment(currentDateView, 'YYYY-MM-DD', true).isValid() ? currentDateView : new Date().toLocaleDateString().split('.').reverse().join('-'));
    const [shifts, setShifts] = useState([]);
    const [machines, setMachines] = useState([]);
    const [extraWorks, setExtraWorks] = useState([]);
    const [categoriesExtraWorksAll, setCategoriesExtraWorksAll] = useState([]);
    const [shiftEdit, setShiftEdit] = useState();
    const navigate = useNavigate();

    useEffect(() => {

        getExtraWorksV1(jwt)
        .then(v => setExtraWorks(v ?? []))
        .catch(e => console.log(e));

        if(isAdmin){
            lasers(jwt)
            .then(v => setMachines(v ?? []))
            .catch(v => console.log(v));

            getExtraWorkCategoriesV1(jwt)
            .then(v => setCategoriesExtraWorksAll(v ?? []))
            .catch(v => console.log(v));
        }

    }, []);

    useEffect(() => {

        if(!currentDate || currentDate.trim() == '')
            return;

        if(currentDate != currentDateView)
            {
                if(isAdmin)
                    navigate(`/Administrator/shifts/editShift/${currentDate}`)
                else  navigate(`/employee/shifts/history/${currentDate}`)
            }

            if(isAdmin)
                getShiftsAllV1(currentDate, jwt)
            .then(v => setShifts(v ?? []))
            .catch(e => console.log(e));
            else getShiftsMyV1(currentDate, jwt)
            .then(v => setShifts(v ?? []))
            .catch(e => console.log(e));

    }, [currentDate]);

    const shiftsUI = shifts.map(shift => <ShiftView 
            extraWorks={extraWorks}
            key={shift.id} 
            data={shift} 
            isAdmin={isAdmin}
            onEdit={s => setShiftEdit(s)}
            />);

    return <div className={styles.historyBody}>
            <div className={styles.panelMove}>
                <label className={styles.dateString}>{currentDate}</label>
                <input className={styles.dateInput} value={currentDate} onChange={e => setCurrentDate(e.target.value)} type='date' />
            </div>
            <div className={styles.content}>
                <div id="listContainer" className={styles.shift_container}>
                    {shiftsUI.length <= 0 ?
                        <div className={styles.emptyShift}>
                            Сегодня нет работ
                        </div> : shiftsUI}
                </div>
            </div>
            {
                shiftEdit ? 
                    <ShiftEdit 
                    shift={shiftEdit} 
                    jwt={jwt}
                    onUpdated={(up) => {
                        const updatedIndex = shifts.findIndex(f => f.id == shiftEdit.id);
                        shifts[updatedIndex] = up;
                        setShifts([...shifts]);
                        setShiftEdit();
                    }}
                    extraWorksAll={extraWorks}
                    categoriesExtraWorksAll={categoriesExtraWorksAll}
                    machinesAll={machines} 
                    show={shiftEdit != null} 
                    onHide={() => setShiftEdit()}/> : ''
            }
        </div>
}