import React, { useEffect, useMemo, useState } from 'react'
import { Alert, Button, Container, Form, ListGroup } from 'react-bootstrap'
import DesktopTable from './DesktopTable'
import MobileTable from './MobileTable'
import {
	deleteWorker,
	getWorkerCategories,
	getWorkerEmploymentTypes,
	getWorkerMathSalary,
	getWorkerMathTime,
	getWorkerQualificationTypes,
	getWorkers,
	getWorkersRemove,
	getWorkerTypes,
	updateWorker,
} from './WorkersAPI'
import Create from './CreateWorker'
import { FaTrash } from 'react-icons/fa'

const Workers = ({ jwt }) => {
	const mobileSize = 1200
	const [workers, setWorkers] = useState([]);
	const [allWorkers, setAllWorkers] = useState([]);

	const [removeWorkers, setRemoveWorkers] = useState([]);

	const [workerCategories, setWorkerCategories] = useState([])
	const [workerTypes, setWorkerTypes] = useState([])
	const [workerMathSalary, setWorkerMathSalary] = useState([])
	const [workerEmploymentTypes, setWorkerEmploymentTypes] = useState([])
	const [workerMathTime, setWorkerMathTime] = useState([])
	const [workerQualification, setWorkerQualification] = useState([]);
	const [isMobile, setIsMobile] = useState(window.screen.width - mobileSize <= 0);
	const [isCreateWorker, setIsCreateWorker] = useState(false);

	const [textSearch, setTextSearch] = useState('');

	useEffect(() => {
		setWorkers(allWorkers.filter(o => (o.name + ' ' + o.firstName + ' ' + o.secondName + ' ' + o.thirdName).toLowerCase().includes(textSearch.toLowerCase())));
	}, [textSearch]);

	useEffect(() => {
		async function didMount() {
			const responseWorkers = await getWorkers(jwt);
			setWorkers(responseWorkers ?? []);
			setAllWorkers(responseWorkers ?? []);
			setWorkerCategories((await getWorkerCategories(jwt)) ?? [])
			setWorkerTypes((await getWorkerTypes(jwt)) ?? [])
			setWorkerMathSalary((await getWorkerMathSalary(jwt)) ?? [])
			setWorkerEmploymentTypes((await getWorkerEmploymentTypes(jwt)) ?? []);
			setWorkerMathTime((await getWorkerMathTime(jwt)) ?? [])
			setWorkerQualification((await getWorkerQualificationTypes(jwt)) ?? []);

			setRemoveWorkers((await getWorkersRemove(jwt)) ?? []);
		}
		didMount()

		setIsMobile(document.body.clientWidth - mobileSize <= 0);
		window.addEventListener('resize', function (event) {
			setIsMobile(document.body.clientWidth - mobileSize <= 0);
		})
	}, [])

	const handleWorkerDelete = id => {
		setAllWorkers(allWorkers.filter(worker => worker.id !== id))
		setWorkers(workers.filter(worker => worker.id !== id))
	}

	const WorkerView = ({ worker, isDeleted }) => {

		return isMobile ? <MobileTable
		isDeleted={isDeleted}
		deleteWorker={deleteWorker}
		employmentTypes={workerEmploymentTypes}
		handleDelete={handleWorkerDelete}
		jwt={jwt}
		mathSalaryTypes={workerMathSalary}
		mathTimeTypes={workerMathTime}
		qualificationTypes={workerQualification}
		updateWorker={updateWorker}
		worker={worker}
		workerCategories={workerCategories}
		workerTypes={workerTypes}
	/> :

		<DesktopTable
			isDeleted={isDeleted}
			deleteWorker={deleteWorker}
			employmentTypes={workerEmploymentTypes}
			handleDelete={handleWorkerDelete}
			jwt={jwt}
			mathSalaryTypes={workerMathSalary}
			mathTimeTypes={workerMathTime}
			qualificationTypes={workerQualification}
			updateWorker={updateWorker}
			worker={worker}
			workerCategories={workerCategories}
			workerTypes={workerTypes}
		/>
	}

	const CategoryView = ({ category, workers }) => {

		if(!workers || workers.length == 0)
			return '';

		return <div className='mt-3'>
			<h3>{category.name}</h3>
			<ListGroup>
			 {
				workers.map(worker => <WorkerView key={worker.id} worker={worker}/>)
			}
		</ListGroup>
		</div>
	}

return <React.Fragment>
	<Container style={{
		maxWidth: '1440px',
		margin: '40px auto',
	}}>
		<h1 style={{ textAlign: 'center' }}>Сотрудники цеха</h1>
		<Button onClick={() => setIsCreateWorker(true)} variant='success'>Создать</Button>
		<Form.Control
			className='mb-3 mt-3'
			type='text'
			value={textSearch}
			onChange={(e) => setTextSearch(e.target.value)}
		/>
		{
			workerCategories.map(map => <CategoryView key={map.id} category={map} workers={workers.filter(f => f.categoryId == map.id)}/>)
		}
		<div className='mt-3' style={{ borderTop: '1px dashed red', paddingTop: 8}}>
			<h3 style={{display: 'flex', alignItems: 'center'}}>
				<FaTrash color='red'/>
				<span>Удаленны</span>
			</h3>
			{
				removeWorkers.length > 0 ?
				removeWorkers.map(map => <WorkerView key={map.id} worker={map} isDeleted={true}/>)
				: <Alert variant='success'>Пусто</Alert>
			}
		</div>
	</Container>
	{
		isCreateWorker ?
			<Create show={isCreateWorker}
				employmentTypes={workerEmploymentTypes}
				mathSalaryTypes={workerMathSalary}
				mathTimeTypes={workerMathTime}
				onHide={() => setIsCreateWorker(false)}
				qualificationTypes={workerQualification}
				workerCategories={workerCategories}
				workerTypes={workerTypes}
				onCreated={() => window.location.reload()}
				jwt={jwt}
			/> : ''
	}
</React.Fragment>
}

export default Workers
