import React, { useState, useEffect } from "react";
import { TimeSpanParse } from "../../../../Core";
import styles from './ShiftEdit.module.css';
import { MdEdit } from "react-icons/md";
import { Badge, FloatingLabel, Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";


export default function ShiftView({ data, extraWorks, isAdmin, onEdit }) {

    const [shift] = useState(data);
    const [zone] = useState(data.zone?.includes('-') ? data.zone.slice(0, 6) : '+' + data.zone?.slice(0, 5));
    const [startView] = useState(new Date(shift.start.replace('Z', '')).addHours(zone.split(':')[0]).toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' }));
    const [endView] = useState(new Date(shift.end.replace('Z', '')).addHours(zone.split(':')[0]).toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' }));

    const ExtraWorkPullView = ({ work }) => {
        return  <OverlayTrigger
        placement={'top'}
        overlay={
          <Tooltip id={`tooltip-top`}>
            {work.description}
          </Tooltip>
        }
      >
        <Badge className={styles.extra_work} pill>
            {extraWorks.find(f => f.id == work.extraWorkId)?.name ?? 'Не найдено'} - {work.value} - {work.points.toFixed(2)}
        </Badge>
      </OverlayTrigger> ;
    }

    return <div className={styles.shift}>
        <div className={styles.shiftHead}>
            <label className={styles.titleDay}>
                {new Date(shift.start.replace('Z', '') + zone).toLocaleString(undefined, { day: 'numeric', month: 'long' })}
            </label>
            {
                isAdmin ? <MdEdit className={styles.shiftEdit} onClick={() => onEdit(shift)} /> : ''
            }
        </div>
        <div className={styles.shift_content}>{
                isAdmin ? 
                <FloatingLabel label="Сотрудник" className="mb-3">
                <Form.Control value={shift.worker} readOnly placeholder="" />
            </FloatingLabel> : ''
            }
            <FloatingLabel label="Время" className="mb-3">
                <Form.Control placeholder="" value={`${startView} - ${endView}`} readOnly />
            </FloatingLabel>
            <FloatingLabel label="Длительность" className="mb-3">
                <Form.Control placeholder="" value={TimeSpanParse(shift.timeSpan)} readOnly />
            </FloatingLabel>
            <FloatingLabel label="Сумма баллов" className="mb-3">
                <Form.Control placeholder="" value={shift.sumPoints} readOnly />
            </FloatingLabel>
            <InputGroup className="mb-3">
                <InputGroup.Text>
                    Пр.
                </InputGroup.Text>
                <Form.Control value={shift.simplePoints} readOnly />
                <InputGroup.Text>
                    Ср.
                </InputGroup.Text>
                <Form.Control value={shift.middlePoints} readOnly />
                <InputGroup.Text>
                    Сл.
                </InputGroup.Text>
                <Form.Control value={shift.highPoints} readOnly />
            </InputGroup>
            <div className={styles.extra_works}>
                <Badge className={styles.extra_work} pill bg={shift.isDayOff ? 'warning' : 'success'}>
                    {shift.isDayOff ? 'Выходной' : 'Рабочий'}
                </Badge>
                <Badge bg="dark" className={styles.extra_work} pill>
                    {zone}
                </Badge>
                {
                    shift.machines.length > 0 ? <Badge bg="dark" className={styles.extra_work} pill>
                        Станки: {shift.machines.length}
                    </Badge> : ''
                }
                {
                    shift.extraWorksPull.map(work => <ExtraWorkPullView key={work.id} work={work} />)
                }
            </div>
        </div>
    </div>

}