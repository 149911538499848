import React, { useEffect, useState } from "react";
import styles from '../Styles/FormulasMath.module.css';
import { Alert, Button, Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getExtraWorks } from "../../../apiMethod/extraWorks";
import { getProducts } from "../../../apiMethod/products";
import EditFormula from "./EditFormulaModal";
import { getFormulaAmounts } from "../../../apiMethod/analyze";
import { FaPlus } from "react-icons/fa";
import CreateFormula from "./CreateFormulaModal";

function FormulasMath({ jwt }) {

    const [productsRationCurrent, setProductsRationCurrent] = useState([]);
    const [productsRationPrev, setProductsRationPrev] = useState([]);

    const [currentMonth, setCurrentMonth] = useState(new Date().toJSON().slice(0, 7));
    const [prevMonth, setPrevMonth] = useState(new Date(new Date() - new Date().getDate() * 24 * 60 * 60 * 1000).toJSON().slice(0, 7));

    const [extraWorks, setExtraWorks] = useState([]);
    const [productions, setProductions] = useState([]);

    const [isCreate, setIsCreate] = useState(false);

    useEffect(() => {

        if(!currentMonth || !prevMonth)
            return;

        getFormulaAmounts(currentMonth + '-01', jwt)
            .then(v => setProductsRationCurrent(v ?? []))
            .catch(console.log);

        getFormulaAmounts(prevMonth + '-01', jwt)
            .then(v => setProductsRationPrev(v ?? []))
            .catch(console.log);

        getExtraWorks(jwt)
            .then(v => setExtraWorks(v ?? []))
            .catch(console.log);

        getProducts(jwt)
            .then(v => setProductions(v ?? []))
            .catch(console.log);
       
    }, [currentMonth, prevMonth]);

    const FormulaItem = ({ item }) => {

        const value = +(item.value * 100).toFixed(2);
        const [isEdit, setIsEdit] = useState(false);

        return <div className={styles.productRation}>
            <div className={styles.productRationName}>
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => <Tooltip {...props}>
                        {item.formula}
                    </Tooltip>}>
                    <div>{item.name}</div>
                </OverlayTrigger>
            </div>
            <svg
                width="100" height="100" viewBox="0 0 100 100"
                className={styles.circular_progress} style={{ '--progress': value }}
                onClick={() => setIsEdit(true)}
            >
                <circle className={styles.bg + ' ' + styles.circle} />
                <circle className={styles.fg + ' ' + styles.circle} />
            </svg>
            <div style={{ position: 'relative', top: '-60px' }}>{value}%</div>
            {
                isEdit ? <EditFormula item={item} show={isEdit} onHide={() => setIsEdit(false)} extraWorks={extraWorks} productions={productions} onUpdated={() => window.location.reload()} jwt={jwt} /> : ''
            }
        </div>
    }

    return <div className={styles.main}>
        <div className={styles.content}>
            <div className={styles.headContent}>
                <label className={styles.titleChart}>Рентабельность</label>
                <Button variant="success" onClick={() => setIsCreate(true)}><FaPlus /></Button>
            </div>
            <div className={styles.monthBlock}>
                <Form.Control
                    style={{ maxWidth: 220 }}
                    type="month"
                    value={currentMonth}
                    onChange={e => setCurrentMonth(e.target.value)}
                    min={prevMonth}
                />
                <div className={styles.stateMonth}>текущий месяц</div>
                <div className={styles.elements}>
                    {
                        productsRationCurrent && productsRationCurrent.length > 0 ?
                            productsRationCurrent.map((item, index) => <FormulaItem key={index} item={item} />)
                            : <Alert variant="danger" dismissible>
                                <Alert.Heading>Ох, щелк! У вас ошибка!</Alert.Heading>
                                <p>
                                    Что-то пошло не по плану, попробуйте перезагрузить страницу! Если не помогло тогда сообщите разработчику и дождитесь обновления.
                                </p>
                            </Alert>
                    }
                </div>
            </div>

            <div className={styles.monthBlock}>
                <Form.Control
                    style={{ maxWidth: 220 }}
                    type="month"
                    value={prevMonth}
                    onChange={e => setPrevMonth(e.target.value)}
                    max={currentMonth}
                />
                <div className={styles.stateMonth}>прошлый месяц</div>
                <div className={styles.elements}>
                    {
                        productsRationPrev && productsRationPrev.length > 0 ?
                            productsRationPrev.map((item, index) => <FormulaItem key={index} item={item} />)
                            : <Alert variant="danger" dismissible>
                                <Alert.Heading>Ох, щелк! У вас ошибка!</Alert.Heading>
                                <p>
                                    Что-то пошло не по плану, попробуйте перезагрузить страницу! Если не помогло тогда сообщите разработчику и дождитесь обновления.
                                </p>
                            </Alert>
                    }
                </div>
            </div>
        </div>
        {
            isCreate ?
                <CreateFormula
                    extraWorks={extraWorks}
                    item={{ name: '', formula: '', formulaMath: '' }}
                    onHide={() => setIsCreate(false)}
                    productions={productions}
                    show={isCreate}
                    onCreated={() => window.location.reload()}
                    jwt={jwt}
                /> : ''
        }
    </div>;
}

export default FormulasMath;