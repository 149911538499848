import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CurrentShift from '../Employee/Page/CurrentShift.js';
import VerfiicationCode from '../Employee/Page/VerificationCode.js';
import Home from './Page/Home.js';
import StartShift from '../Employee/Page/startShift.js';
import LoginPage from '../Main/LoginPage.js';
import Favorites from './Page/Favorites.js';
import ShiftsHistory from '../Administrator/Shifts/ShiftsHistory/ShiftsHistory.jsx';

function NavbarEmployee(props) {

  return (
    <div>
      <Routes>
        <Route path="/*" element={<Home jwt={props.jwt}/>} />
        <Route path="/shifts">
          <Route path="history/:currentDateView?" element={<ShiftsHistory isAdmin={false} jwt={props.jwt}/>} />
          <Route path='current' element={<CurrentShift />} />
        </Route>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/startShift' element={<StartShift />} />
        <Route path='/extraworks/favorites' element={<Favorites />} />
        <Route path='/timespan'>
          <Route path='verificationCode' element={<VerfiicationCode />} />
        </Route>
      </Routes>
    </div>
  );
}

export default NavbarEmployee;