import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default function ExtraWorkEdit({ extraWork, show, onHide, onUpdated, onDeleted }) {

    const [value, setValue] = useState(extraWork.value);
    const [description, setDescription] = useState(extraWork.description ?? '');

    const onSumbit = (e) => {
        e.preventDefault();

        onUpdated({
            ...extraWork,
            value: +value,
            description: description
        })
    };

    return <Modal show={show} onHide={onHide}>
        <Form onSubmit={onSumbit}>
            <Modal.Header closeButton>
                <Modal.Title>{extraWork.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Значение</Form.Label>
                    <Form.Control
                        required
                        type="number"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Описание</Form.Label>
                    <Form.Control
                        type="text"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={onDeleted}>Удалить</Button>
                <Button variant="secondary" onClick={onHide}>Закрыть</Button>
                <Button variant="success" type="sumbit">Сохранить</Button>
            </Modal.Footer>
        </Form>
    </Modal>
}