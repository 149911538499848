import { Route, Routes } from 'react-router-dom'
import Workers from '../Administrator/Workers/Workers.jsx'
import Warehouse from '../Manager/ProductsWarehouse/main.jsx'
import AnalyticLasers from './AnalyticLasers.js'
import Codes from './Codes.js'
import ExcelExport from './ExcelExport.js'
import ExtraWorksView from './ExtraWorksView.js'
import Home from './HomePage'
import Notifications from './Notifications/Notifications.jsx'
import RawWarehouse from './RawMaterialWarehouse/main.jsx'
import Salary from './Salary/Salary.jsx'
import ShiftCurrent from './Shifts/ShiftCurrent.js'
import ShiftsHistory from './Shifts/ShiftsHistory/ShiftsHistory.jsx'
import WorkSchedule from './WorkSchedule/WorkSchedule.jsx'
import CalculateOrderCost from './CalculateOrderCost/Main.jsx'
import CalculateOrderCostEdit from './CalculateOrderCost/Edit/Main.jsx'

function Navbar(props) {
	return (
		<div>
			<Routes>
				<Route path='/*' element={<Home />} />
				<Route path='/excel' element={<ExcelExport jwt={props.jwt} />} />
				<Route path='/shifts'>
					<Route path='editShift/:currentDateView?' element={<ShiftsHistory isAdmin={true} jwt={props.jwt}/>} />
					<Route path='currentShifts' element={<ShiftCurrent />} />
				</Route>
				<Route path='/lasers' element={<AnalyticLasers jwt={props.jwt} />} />
				<Route path='/workers' element={<Workers jwt={props.jwt} />} />
				<Route path='/calculateOrderCost'>
					<Route path='' element={<CalculateOrderCost jwt={props.jwt}/>}/>
					<Route path='edit' element={<CalculateOrderCostEdit jwt={props.jwt}/>}/>
				</Route>
				<Route
					path='/productWarehouse'
					element={<Warehouse jwt={props.jwt} />}
				/>
				<Route
					path='/rawMaterialWarehouse'
					element={<RawWarehouse jwt={props.jwt} />}
				/>
				<Route
					path='/extraWorks'
					element={<ExtraWorksView jwt={props.jwt} />}
				/>
				<Route
					path='/notifications'
					element={<Notifications jwt={props.jwt} />}
				/>
				<Route path='/salary' element={<Salary jwt={props.jwt} />} />
				<Route
					path='/workschedule'
					element={<WorkSchedule jwt={props.jwt} />}
				/>
				<Route path='/timespan'>
					<Route path='codes' element={<Codes />} />
				</Route>
			</Routes>
		</div>
	)
}

export default Navbar
